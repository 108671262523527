<template>
  <form
    action=""
    :class="[$style.search, { [$style.archives]: type === 'archives' }]"
    role="search"
    @click.stop
    @keypress.enter.prevent="$emit('search-state', false)"
  >
    <div>
      <label v-if="props.showLabel" for="searchInput" class="heading-4">Search</label>
      <p v-if="props.preHint" class="body-2">{{ props.preHint }}</p>
      <input
        id="searchInput"
        v-model="searchQuery"
        :class="$style.searchInput"
        enterkeyhint="search"
        :ga4-event='`{"click_type": "search", "component": "SearchModal", "content_group": "search", "gtm_tag": "interaction", "link_text": "(submit)"}`'
        type="search"
        @keypress.enter.prevent="startSearch()"
      />
      <input v-if="props.type" name="post_type" :value="props.type" type="hidden" />
    </div>
    <button
      :aria-label="searchQuery ? `Search for ${searchQuery}` : null"
      class="button-primary"
      type="submit"
      @click.prevent="startSearch()"
      @keypress.enter.prevent="startSearch()"
    >
      <IconMagnify></IconMagnify>
    </button>
    <p v-if="props.postHint" class="body-2">{{ props.postHint }}</p>
  </form>
</template>

<script setup lang="ts">
import { AppConfig } from '@/types/global.interface';
import IconMagnify from '@/assets/svgs/IconMagnify.svg?component';
import { inject, onMounted, ref } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useScreenSize } from '@/composables/Common.js';

const appConfig = inject<AppConfig>('appConfig');

const route = useRoute();
const router = useRouter();

const { isMobile } = useScreenSize();

const searchQuery = ref('');

const emit = defineEmits({
  mounted: false,
  'search-state': null,
});

const props = withDefaults(
  defineProps<{
    placeholder?: string;
    postHint?: string;
    preHint?: string;
    showLabel?: boolean;
    type?: string;
  }>(),
  {
    placeholder: 'Search Guggenheim.org',
    postHint: '',
    preHint: '',
    showLabel: true,
    type: '',
  },
);

onMounted(() => {
  emit('mounted', true);
  searchQuery.value = route.query.s || null;
});

function startSearch(): void {
  if (isMobile()) {
    window.location.href = `${appConfig.homeUrl}search?s=${searchQuery.value}&page=1${props.type ? `&post_type=${props.type}` : ''}`;
  }

  if (!isMobile()) {
    props.type
      ? router.push({
          name: 'Search',
          query: { s: searchQuery.value, page: 1, post_type: props.type },
        })
      : router.push({ name: 'Search', query: { s: searchQuery.value, page: 1 } });
  }

  emit('search-state', false);
}
</script>

<style lang="scss" module>
.search {
  align-items: flex-end;
  display: flex;
  flex-wrap: wrap;
  margin-top: 1rem;
  max-width: 870px;
  width: 100%;

  button[type='submit'] {
    height: 52px;
    margin: 0;
    padding-bottom: 0;
    padding: 0;

    svg {
      height: 2rem;
      padding: 0 40px;
    }
  }

  > div {
    display: flex;
    flex: 1;
    flex-wrap: wrap;
    width: 100%;
  }

  input[type='search'] {
    flex: 1;
    margin-right: 1rem;
    width: inherit;

    &::-webkit-search-cancel-button {
      margin-left: 1rem;
    }
  }

  input {
    &::-webkit-search-cancel-button {
      -webkit-appearance: none;
      background-image: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12.6663 4.27325L11.7263 3.33325L7.99967 7.05992L4.27301 3.33325L3.33301 4.27325L7.05967 7.99992L3.33301 11.7266L4.27301 12.6666L7.99967 8.93992L11.7263 12.6666L12.6663 11.7266L8.93967 7.99992L12.6663 4.27325Z' fill='%23111111'/%3E%3C/svg%3E");
      height: 1rem;
      position: relative;
      right: px-to-rem(10);
      width: 1rem;
    }
  }

  label {
    flex-basis: 100%;
  }

  p {
    flex-basis: 100%;
    margin-left: 0;
    margin-top: px-to-rem(6);
  }

  &.archives {
    flex-wrap: initial;

    p {
      @include space-8-10-above;
    }

    .search-input {
      @include space-8-10-above;
    }
  }
}
.search-input {
  @include body-1;

  & {
    -webkit-appearance: none;
    border: 2px solid $black;
    border-radius: 0;
    display: inline-block;
    grid-column: span 9;
    outline: none;
    padding: px-to-rem(10) 0 px-to-rem(10) 1rem;
  }
}

@media (min-width: $break-point) {
  .search {
    input[type='search'] {
      width: auto;
    }
  }
}
</style>
